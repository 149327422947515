import React, { FC, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { Paper, styled, Typography } from "@mui/material";
import { useGetTotalMembershipLevelUsersQuery } from "generated/graphql";
import { useWindowSize } from "react-use";

const MembershipLevelCard: FC = () => {
  const { data } = useGetTotalMembershipLevelUsersQuery();
  const width = useWindowSize()?.width;
  const [series, options] = useMemo(() => {
    const series: ApexAxisChartSeries | ApexNonAxisChartSeries = [
      data?.getTotalMembershipLevelUsers?.MembershipLevel300 || 0,
      data?.getTotalMembershipLevelUsers?.MembershipLevel200 || 0,
      data?.getTotalMembershipLevelUsers?.MembershipLevel100 || 0,
    ];
    const options: ApexCharts.ApexOptions = {
      labels: [
        "Membership Level 300",
        "Membership Level 200",
        "Membership Level 100",
      ],
      chart: {
        width: width <= 500 ? 280 : 400,
        type: "donut",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },
      dataLabels: {
        enabled: true,
      },
      fill: {
        type: "gradient",
      },
      legend: {
        formatter: function (val, opts) {
          return val + " : " + opts.w.globals.series[opts.seriesIndex];
        },
        position: "bottom",
      },
      // responsive: [
      //   {
      //     breakpoint: 500,
      //     options: {
      //       chart: {
      //         width: 280,
      //       },
      //     },
      //   },
      // ],
      title: {
        text: "Active Membership level",
      },
    };
    return [series, options];
  }, [data?.getTotalMembershipLevelUsers, width]);

  return (
    <Container>
      <ReactApexChart
        series={series}
        options={options}
        type="donut"
        width={380}
      />

      <Typography align="center" p={5}>
        Membership level Null :{" "}
        {data?.getTotalMembershipLevelUsers?.MembershipLevelNull}
      </Typography>
    </Container>
  );
};

export default MembershipLevelCard;

const Container = styled(Paper)({
  "@media (max-width:500px)": {
    // maxWidth: 289,
    width: "100%",
  },
  padding: "14px 10px",
  ".apexcharts-legend ": {
    maxWidth: "200px",
    width: "100%",
    margin: "auto",
  },
});
